import * as React from "react";
import { Link } from "gatsby";

import Seo from "../components/seo";
import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
} from "../components/Icons";
import Layout from "../components/layout";
import Navbar from "../components/Navbar";
import Header from "../components/header";

const ContactPage = () => (
  <div className="">
    <Seo title="Contact" />

    <Layout>
     <Header siteTitle={"Saltwood Homes"} />
    <div>
      <h2 className="underline pb-4 text-xl">Contact:</h2>
      <p>
        <div className="flex items-center space-x-2">
        <MailIcon />
          <p> saltwoodhomes@gmail.com</p>
        </div>
      </p>
      <p>
        <div className="flex items-center space-x-2">
        <PhoneIcon />
          <p className="my-auto">+18082989310</p>
        </div>
      </p>
    </div>
  </Layout>
  </div>
    
);

export default ContactPage;
